import "../styles/global.css"
import styles from "../styles/Header.module.css"

function Logo() {
    return (
        <div className={styles.logo}>
            <svg version="1.1" viewBox="0 0 234.19 265.31" height="100%" xmlns="http://www.w3.org/2000/svg">
                <g id="Droite">
                    <g id="Bas">
                        <path d="m227.43 253.27h-73.49s-0.03 0-0.04 0-8.92 0-8.92 0c-1.93 0-3.07 2.18-1.96 3.76l4.38 6.27c0.44 0.63 1.15 1 1.91 1h4.59s0 0 0 0h0.04 73.49c2.98 0 5.39-2.42 5.39-5.39v-0.25c0-2.98-2.42-5.39-5.39-5.39z" />
                        <path d="m198.93,232.25h-60.97s-.02,0-.03,0h-7.67c-1.93,0-3.07,2.18-1.96,3.76l4.38,6.27c.44.63,1.15,1,1.91,1h3.34s.02,0,.03,0h60.97c2.98,0,5.39-2.42,5.39-5.39v-.25c0-2.98-2.42-5.39-5.39-5.39Z" />
                        <path d="m170.47 211.21h-46.01s-0.05 0-0.08 0-8.92 0-8.92 0c-1.93 0-3.07 2.18-1.96 3.76l4.38 6.27c0.44 0.63 1.15 1 1.91 1h4.59s0 0 0 0h0.08 46.01c2.98 0 5.39-2.42 5.39-5.39v-0.25c0-2.98-2.42-5.39-5.39-5.39z" />
                        <path d="m142.31,190.2h-33.82s-.02,0-.02,0h-7.65c-1.93,0-3.07,2.18-1.96,3.76l4.38,6.27c.44.63,1.15,1,1.91,1h3.31s.02,0,.03,0h33.82c2.98,0,5.39-2.42,5.39-5.39v-.25c0-2.98-2.42-5.39-5.39-5.39Z" />
                        <path d="m113.67,169.18h-20.1s-.02,0-.03,0h-7.43c-1.93,0-3.07,2.18-1.96,3.76l4.38,6.27c.44.63,1.15,1,1.91,1h3.1s.02,0,.03,0h20.1c2.98,0,5.39-2.42,5.39-5.39v-.25c0-2.98-2.42-5.39-5.39-5.39Z" />
                        <path d="m85.45,148.16h-6.04s-.02,0-.03,0h-8.06c-1.93,0-3.07,2.18-1.96,3.76l4.38,6.27c.44.63,1.15,1,1.91,1h3.73s.02,0,.03,0h6.04c2.98,0,5.39-2.42,5.39-5.39v-.25c0-2.98-2.42-5.39-5.39-5.39Z" />
                    </g>
                    <circle id="Cercle" cx="61.45" cy="132.65" r="5.52" />
                    <g id="Haut">
                        <path d="m85.45,106.12h-5.75s-.02,0-.03,0h-3.95c-.76,0-1.48.37-1.91,1l-4.38,6.27c-1.11,1.59.03,3.76,1.96,3.76h8.29s.02,0,.03,0h5.75c2.98,0,5.39-2.42,5.39-5.39v-.25c0-2.98-2.42-5.39-5.39-5.39Z" />
                        <path d="m113.55,85.09h-19.13s-.02,0-.03,0h-3.95c-.76,0-1.48.37-1.91,1l-4.38,6.27c-1.11,1.59.03,3.76,1.96,3.76h8.29s.02,0,.02,0h19.13c2.98,0,5.39-2.42,5.39-5.39v-.25c0-2.98-2.42-5.39-5.39-5.39Z" />
                        <path d="m142.19,64.07h-32.41s-.02,0-.03,0h-4.52c-.76,0-1.48.37-1.91,1l-4.38,6.27c-1.11,1.59.03,3.76,1.96,3.76h8.85s.02,0,.02,0h32.41c2.98,0,5.39-2.42,5.39-5.39v-.25c0-2.98-2.42-5.39-5.39-5.39Z" />
                        <path d="m170.35,43.05h-45.97s-.02,0-.03,0h-4.52c-.76,0-1.48.37-1.91,1l-4.38,6.27c-1.11,1.59.03,3.76,1.96,3.76h8.85s.02,0,.02,0h45.97c2.98,0,5.39-2.42,5.39-5.39v-.25c0-2.98-2.42-5.39-5.39-5.39Z" />
                        <path d="m198.8,22.03h-59.75s-.02,0-.03,0h-4.46c-.76,0-1.48.37-1.91,1l-4.38,6.27c-1.11,1.59.03,3.76,1.96,3.76h8.79s.02,0,.02,0h59.75c2.98,0,5.39-2.42,5.39-5.39v-.25c0-2.98-2.42-5.39-5.39-5.39Z" />
                        <path d="m227.79,1h-75.6s-.03,0-.05,0h-2.76c-.76,0-1.48.37-1.91,1l-4.38,6.27c-1.11,1.59.03,3.76,1.96,3.76h82.75c2.98,0,5.39-2.42,5.39-5.39v-.25c0-2.98-2.42-5.39-5.39-5.39Z" />
                    </g>
                </g>
                <g id="Gauche">
                    <path d="m107.32,260.54l-4.38-6.27c-.44-.63-1.15-1-1.91-1h-4.59s0,.07,0,.07c-.27-.04-.55-.07-.83-.07H6.52c-3.05,0-5.52,2.47-5.52,5.52s2.47,5.52,5.52,5.52h89.09c.28,0,.56-.03.83-.07v.07h8.92c1.93,0,3.07-2.18,1.96-3.76Z" />
                    <path d="m92.54,239.52l-4.38-6.27c-.44-.63-1.15-1-1.91-1h-4.59s0,.09,0,.09c-.31-.05-.63-.09-.95-.09H6.52c-3.05,0-5.52,2.47-5.52,5.52s2.47,5.52,5.52,5.52h74.2c.33,0,.64-.03.95-.09v.09h8.92c1.93,0,3.07-2.18,1.96-3.76Z" />
                    <path d="m77.64,218.48l-4.38-6.27c-.44-.63-1.15-1-1.91-1h-4.44s0,0,0,0H6.75c-3.05,0-5.52,2.47-5.52,5.52s2.47,5.52,5.52,5.52h68.94c1.93,0,3.07-2.18,1.96-3.76Z" />
                    <path d="m63.01,197.48l-4.38-6.27c-.44-.63-1.15-1-1.91-1h-4.19c-.08,0-.17-.01-.25-.01H6.52c-3.05,0-5.52,2.47-5.52,5.52h0c0,3.05,2.47,5.52,5.52,5.52h45.61s8.92.01,8.92.01c1.93,0,3.07-2.18,1.96-3.76Z" />
                    <path d="m48.33,176.45l-4.38-6.27c-.44-.63-1.15-1-1.91-1h-4.44s0,0,0,0H6.61c-3.05,0-5.52,2.47-5.52,5.52s2.47,5.52,5.52,5.52h39.77c1.93,0,3.07-2.18,1.96-3.76Z" />
                    <path d="m33.62,155.43l-4.38-6.27c-.44-.63-1.15-1-1.91-1h-3.28s0,0,0,0H6.61c-3.05,0-5.52,2.47-5.52,5.52s2.47,5.52,5.52,5.52h25.05c1.93,0,3.07-2.18,1.96-3.76Z" />
                    <path id="Separateur" d="m21.85 138.17h-15.24c-3.05 0-5.52-2.47-5.52-5.52s2.47-5.52 5.52-5.52h15.24c3.05 0-0.64 2.47-0.64 5.52s3.69 5.52 0.64 5.52z" />
                    <path d="m31.66,106.12h-8.92s-16.13,0-16.13,0c-3.05,0-5.52,2.47-5.52,5.52s2.47,5.52,5.52,5.52h16.27s.02,0,.03,0h4.41c.76,0,1.48-.37,1.91-1l4.38-6.27c1.11-1.59-.03-3.76-1.96-3.76Z" />
                    <path d="m46.37,85.1H6.61c-3.05,0-5.52,2.47-5.52,5.52s2.47,5.52,5.52,5.52h35.44c.76,0,1.48-.37,1.91-1l4.38-6.27c1.11-1.59-.03-3.76-1.96-3.76Z" />
                    <path d="m61.15,64.08H6.61c-3.05,0-5.52,2.47-5.52,5.52s2.47,5.52,5.52,5.52h50.21c.76,0,1.48-.37,1.91-1l4.38-6.27c1.11-1.59-.03-3.76-1.96-3.76Z" />
                    <path d="m75.74,43.05h-8.92s-60.22,0-60.22,0c-3.05,0-5.52,2.47-5.52,5.52s2.47,5.52,5.52,5.52h60.36s.07,0,.1,0h4.34c.76,0,1.48-.37,1.91-1l4.38-6.27c1.11-1.59-.03-3.76-1.96-3.76Z" />
                    <path d="m90.5,22.03H6.61c-3.05,0-5.52,2.47-5.52,5.52s2.47,5.52,5.52,5.52h76.29s0,0,0,0h3.26c.76,0,1.48-.37,1.91-1l4.38-6.27c1.11-1.59-.03-3.76-1.96-3.76Z" />
                    <path d="m105.52,1.01H6.61C3.56,1.01,1.09,3.47,1.09,6.52s2.47,5.52,5.52,5.52h90.89s0,0,0,0h3.69c.76,0,1.48-.37,1.91-1l4.38-6.27c1.11-1.59-.03-3.76-1.96-3.76Z" />
                </g>
            </svg >
        </div>
    );
}

export default Logo;
